import { LinkTreeButtons } from "../components/LinkTreeButtons";
import { Logo } from "../components/Logo";
import { Maps } from "../components/Maps";
import FondosEuropeos from "../images/fondosEuropeos.jpg";

import "./LinkTree.scss";

const telephone = "654137323";

export const LinkTree: React.FC = () => (
  <div className="link-tree">
    <div className="center">
      <Logo />
      <LinkTreeButtons />
      <Maps />
      <div className="telephone">
        Información y reservas: <a href={`tel:${telephone}`}>{telephone}</a>
      </div>
      <img src={FondosEuropeos} className="fondosEuropeos" alt="Banner ayuda Fondos Europeos" />
    </div>
  </div>
);
