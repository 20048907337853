import { Link } from "react-router-dom";
import { routes } from "../constants/routes";

import "./LinkTreeButtons.scss";

export const LinkTreeButtons: React.FC = () => (
  <div className="link-tree-buttons">
    <Link to={`/${routes.menu}`}>Carta</Link>
    {/* <a href="https://tripadvisor.es/ElPicatelBar">TripAdvisor</a> */}
    <a href="https://twitter.com/ElPicatelBar">Twitter</a>
    <a href="https://instagram.com/ElPicatelBar">Instagram</a>
    <a href="https://facebook.com/ElPicatelBar">Facebook</a>
  </div>
);
