import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LinkTree } from "./views/LinkTree";
import { Menu } from "./views/Menu";
import { routes } from "./constants/routes";

export const App: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path={routes.menu} element={<Menu />} />
      <Route path={routes.base} element={<LinkTree />} />
      <Route path={routes.qrMenu} element={<Navigate to={`/${routes.menu}`} replace />} />
      <Route path="*" element={<Navigate to={`/${routes.base}`} replace />} />
    </Routes>
  </BrowserRouter>
);
