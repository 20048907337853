import React, { memo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocumentProxy } from "pdfjs-dist";
import useWindowDimensions from "../hooks/useWindowDimensions";

import "./PdfReader.scss";
import { routes } from "../constants/routes";
pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";

interface PdfReaderProps {
  fileName: string;
}

export const PdfReader: React.FC<PdfReaderProps> = memo<PdfReaderProps>(({ fileName }: PdfReaderProps) => {
  const [numPages, setNumPages] = useState<number>(0);
  const { width } = useWindowDimensions();

  if (width > 800) {
    window.location.replace(routes.menuPdfFile);
    return null;
  }

  const onDocLoadSuccess = (pdf: PDFDocumentProxy) => {
    setNumPages(pdf.numPages);
  };

  return (
    <Document
      file={fileName}
      loading={<div className="loader" />}
      onLoadSuccess={onDocLoadSuccess}
      onLoadError={console.error}
      renderMode="svg"
    >
      {numPages > 0 &&
        Array.from(new Array(numPages), (_, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            className="page"
            loading={<div className="loader" />}
            width={width}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        ))}
    </Document>
  );
});
