import "./Maps.scss";

export const Maps: React.FC = () => (
  <iframe
    className="gmaps"
    title="Google Maps"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3062.6000066451184!2d-4.0212908!3d39.86079610000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6a0b437a690afd%3A0xcee819d0e82da98b!2sEl%20Picatel!5e0!3m2!1ses!2ses!4v1669469164712!5m2!1ses!2ses"
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
);
